* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
}

input {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background: white;
    color: black;
    border: 1px solid;
    border-radius: 5px;
}

button {
    border: solid 1px;
    padding: 4px;
    min-width: 100px;
    cursor: pointer;
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-transform: uppercase;
    margin: 5px;
    font-size: 12px;
}

table {
    width: 100%;
    font-family: monospace;
    border-spacing: 0;
    /*padding: 2px;*/
}

thead {
    position: sticky;
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    /*top: 0;*/
    top: -5px;
}

td, th {
    padding: 5px;
    font-weight: 500;
}


.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.document-page-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*height: 98vh;*/
}

.btn-agree {
    border-color: green;
    color: var(--tg-theme-text-color);
}

.btn-disagree {
    border-color: red;
    color: var(--tg-theme-text-color);
}

.button-container {
    display: flex;
    padding: 20px 10px 0;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.document-all-details-container {
    width: 100%;
    padding-top: 10px;
    /*overflow-y: auto;*/
}
.document-details-container {
    width: 100%;
    padding: 5px 10px 10px 10px;
    overflow-y: auto;
}

.detail-checkbox-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 6px;
}

.detail-label {
    padding-left: 4px;
}

.detail-checkbox {
    padding: 0;
    margin: 0;
    width: 20px;
}

.document-position-text {
    border-color: inherit;
    text-align: left;
    vertical-align: top;
    padding-left: 0;
}

.document-position-digit {
    border-color: inherit;
    text-align: right;
    vertical-align: top;
    white-space: nowrap;
}

.document-position-title {
    font-family: monospace;
    text-align: left;
    padding-left: 10px;
    font-size: 17px;
}

.document-item {
    font-family: monospace;
    padding-bottom: 5px;
}