.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}
.auth-message {
    font-family: monospace;
    font-size: large;
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    text-align: center;
    padding: 10px;
}
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    margin-top: -0.625em;
    width: 100%;
}