.center {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.document-not-found {
    font-family: monospace;
    font-size: x-large;
    width: 100%;
    height: 25px;
    text-align: center;
}

.comment-container {
    width: 100%;
    padding: 0 10px;
}